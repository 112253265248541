* {
  box-sizing: border-box;
}

a:link, a:visited, a:hover, a:active {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.App_ {
  text-align: center;
}

.header {
  background-color: #444;
  height: 70px;
  display: absolute;
  font-size: calc(8px + 2vmin);
  color: #fff;
  font-family: Georgia, Palatino, "Palatino Linotype", Times, "Times New Roman", serif;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.nav {
  display: flex;
  justify-content: space-evenly;
  vertical-align: middle;
  padding: 10px;
}

.App-nav li {
  padding: 10px;
}

.App-nav li a {
  text-decoration: none;
}

.App-nav li.active {
  background-color: #626C80;
}

.App-nav li:hover {
  background-color: #626C80;
  cursor: pointer;
}

.App-content_ {
  padding: 10px;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

